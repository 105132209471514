<script setup lang="ts">
import { onMounted, ref } from 'vue'
// import { OverlayPanelStyle } from './preset-styles'
import LogoutIcon from '~icons/tabler/logout'
// import UserCircleIcon from '~icons/tabler/user-circle'
import { useAuthManager } from '~/auth-module'
import type { User } from '~/auth-module'
import ChevronDownIcon from '~icons/tabler/chevron-down'
import { LoadingSpinner } from '@t16t/shared/components'
import FullScreenLoadingSpinner from './FullScreenLoadingSpinner.vue'

withDefaults(defineProps<{ 
    compact?: boolean 
}>(), { 
    compact: false 
});

const op = ref();
const { authManager } = useAuthManager();
const user = ref<User>();
const loading = ref(false);
const signOutLoading = ref(false);

onMounted(async () => {
    loading.value = true
    user.value = await authManager.user();
    loading.value = false
})

async function signOut() {
    signOutLoading.value = true;
    await authManager.signOut();
    // No need for routng because logto does a manual location refresh to specified post-logout uri
    // router.push({ name: 'Auth'});
    signOutLoading.value = false;
}

function toggle(event: any) {
  op.value.toggle(event)
}
</script>

<template>
    <FullScreenLoadingSpinner :visible="signOutLoading">
    </FullScreenLoadingSpinner>
    <div v-bind="$attrs">
        <div
        class="flex justify-center gap-2 p-2 px-0 rounded-lg cursor-pointer hover:bg-surface-100"
        @click="toggle"
        >
            <div class="flex gap-1">    
                <img
                    :src="user?.profile_picture_url || `https://ui-avatars.com/api/?background=304378&color=fff&name=${user?.email}`"
                    class="rounded-full"
                    :class="compact ? 'size-8' : 'size-10'"
                >
                <ChevronDownIcon class="size-4 self-end"></ChevronDownIcon>
            </div>
            <div v-if="!compact">
                <p class="text-surface-700 -mb-2">
                    {{ user?.name }}
                </p>
                <small class="text-surface-700 opacity-75">
                    {{ user?.email }}
                </small>
            </div>
            <!-- TODO: OverlayPanelStyle -->
            <OverlayPanel ref="op">
                <div class="p-0">
                    <div v-if="compact" class="p-0">
                        <!-- <p class="font-medium text-sm text-surface-700">
                            {{ user?.name || user.providerId }}
                        </p> -->
                        <p class="font-medium text-sm text-surface-700 opacity-100">
                            {{ user?.email }}
                        </p>
                    </div>
                    <!-- <NuxtLink
                        to="/user/profile"
                        class="flex items-center gap-1 text-surface-700 cursor-pointer rounded-lg p-3 hover:bg-surface-100"
                    >
                        <UserCircleIcon class="size-5" />
                        <span class="">
                            Profile
                        </span>
                    </NuxtLink> -->
                    <div class="border-t border-surface-300 my-4"></div>
                    <div
                        class="flex items-center gap-1 text-red-700 cursor-pointer rounded-lg p-3 hover:bg-surface-100"
                        @click="signOut"
                    >
                        <LogoutIcon class="size-5" />
                        <span class="">
                            Sign out
                        </span>
                        <LoadingSpinner v-if="signOutLoading" class="ms-auto"></LoadingSpinner>
                    </div>
                </div>
            </OverlayPanel>
        </div>
    </div>
</template>
