<script setup lang="ts">
import { LoadingSpinner } from '@t16t/shared/components'
import { useAuthGuard } from '~/auth-module';
import CreditCardIcon from '~icons/tabler/credit-card'
import BuildingStoreIcon from '~icons/tabler/building-store'
import BooksIcon from '~icons/tabler/books'
import HelpCircleIcon from '~icons/tabler/help-circle'
import { ref, type Component } from 'vue';
import UserDropdown from '~/components/UserDropdown.vue';

const { isAuthenticating } = useAuthGuard();

interface MenuItem {
  label: string
  route?: string
  iconComponent?: Component

}

const items = ref<MenuItem[]>([
  {
    label: 'Sessions',
    iconComponent: BooksIcon,
    route: '/learning-session/history',
  },
  {
    label: 'Store',
    iconComponent: BuildingStoreIcon,
    route: '/store',
  },
  {
    label: 'Wallet',
    iconComponent: CreditCardIcon,
    route: '/wallet',
  },
])
</script>

<template>
  <div class="max-w-screen-lg mx-auto min-h-screen font-inter">
    <div v-if="isAuthenticating" class="h-screen flex items-center justify-center">
      <LoadingSpinner class="w-20 h-20 text-primary-700" />
    </div>
    <div
      v-else
      class="flex flex-col lg:flex-row"
    >
      <div
        class="min-h-screen w-full overflow-y-auto bg-surface-100 border border-surface-200 shadow-inner lg:rounded-b-xl lg:rounded-t-xl lg:my-2"
      >
      <div class="flex">
        <UserDropdown 
          :compact="true"
          class="w-fit mr-auto ms-6 mt-6"
        ></UserDropdown>
        <NuxtLink 
          :to="{ name: 'GetStartedGuide' }"
          class="me-6 mt-8"
        >
          <HelpCircleIcon class="size-6 text-surface-700"></HelpCircleIcon>
        </NuxtLink>
      </div>
      <div class="fixed bottom-4 inset-x-0 z-10 w-fit mx-auto flex gap-2 md:gap-4 bg-surface-0 rounded-xl mt-4 border border-surface-200 shadow-md">
        <NuxtLink 
          v-for="item in items"
          :key="item.route"
          :to="item.route" 
          active-class="text-primary-700"
        >
          <div class="flex gap-2 cursor-pointer p-4">
            <Component :is="item.iconComponent" class="size-4 md:size-6"></Component>
            <p class="text-xs md:text-base font-semibold">
              {{ item.label }}
            </p>
          </div>
        </NuxtLink>
      </div>
        <slot />
      </div>
    </div>
  </div>
</template>
